var site = site || {};
site.product = site.product || {};
site.product.view = site.product.view || {};
var presentAfterpay = presentAfterpay || {};

var getUrlQueryParam = function(key) {
  if (typeof URLSearchParams === 'function') {
    var queryString = new URLSearchParams(location.search);
    var codeParam = queryString.get(key);
    return codeParam;
  } else {
    key = key.replace(/\[/, '\\[').replace(/\]/, '\\]');
    var regex = new RegExp('[\\?&]' + key + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
}

site.product.view.full = function (args) {
  var that = {
    productData : args.productData,
    productId : args.productData.PRODUCT_ID,
    productCode : args.productData.skus[0].PRODUCT_CODE
  };

  var $sizeSelect = $('.spp-product__price-size-select');
  var $skintypeSelect = $('.spp-product__price-size-select');
  var $olapicWidgetNode = $('#olapic_specific_widget');
  var $olapicWidgetWrapperNode = $('.js-olapic-widget');

  $(document).on('selectSizeParsedSKU', $sizeSelect, function() {
    var size = getUrlQueryParam('size');
    var skuData = getSkuData('PRODUCT_SIZE', size);
    if(skuData){
      $sizeSelect.find('[data-sku-base-id=' + skuData.SKU_BASE_ID + ']').prop('selected', true);
      $sizeSelect.selectBox('refresh');

      site.skuSelect(skuData);
      $(document).trigger('sku:select', skuData);

      afterPayMessage();
    }
  });

  var getSkuData = function(key, value) {
    return _.find(that.productData.skus, function(sku){ return sku[key] == value; });
  }

  var updateUrlQueryParam = function(key, value) {
    var queryParams = window.location.href.split('?')[1] || "";
    var newQueryParam = key + `=` + value;
    if(queryParams.includes(key)){
      var pattern = new RegExp(key + '=[^&]*','g');
      queryParams = queryParams.replace(pattern, newQueryParam);
    } else {
      queryParams = queryParams + '&' + newQueryParam
    }
    history.replaceState({}, '', '?' + queryParams);
  }

  var sizeUrlInitialParam = getUrlQueryParam('size');
  if(!!sizeUrlInitialParam){
    $(document).trigger('selectSizeParsedSKU');
  }

  if ($olapicWidgetWrapperNode.length && $olapicWidgetNode.length) {
    if ($olapicWidgetNode.hasClass('olapic_items_0')) {
      $olapicWidgetNode.closest('.js-olapic-widget').hide();
    } else {
      var observer = new MutationObserver(function(mutationsList) {
        var detectItemClass = $olapicWidgetNode.attr('class').indexOf('olapic_items_');
        for (var mutation in mutationsList) {
          if (detectItemClass !== -1) {
            if ($olapicWidgetNode.hasClass('olapic_items_0')) {
              $olapicWidgetNode.closest('.js-olapic-widget').hide();
            }
            this.disconnect();
          }
        }
      });
      observer.observe($olapicWidgetNode[0], { attributes: true });
    }
  }

  $sizeSelect.on('change', function() {
    var strikeoutPriceNode;
    var optionTextHtml;
    var selectedSku = $(this).find('option:selected').attr('data-sku-base-id');
    var skuData = getSkuData('SKU_BASE_ID', selectedSku);
    strikeoutPriceNode = $(this).parent().siblings('.js-product-formatted-price');

    if (strikeoutPriceNode.length) {
      if (skuData.formattedPrice2) {
        optionTextHtml =
        '<span class="product__price--non-sale">' +
          skuData.formattedPrice2 +
        '</span><span class="on-sale  product__price--sale">' +
          skuData.formattedPrice +
        '</span>';
      } else {
        optionTextHtml =
        '<span class="product-price product__price--bold">' +
          skuData.formattedPrice +
        '</span>';
      }
      strikeoutPriceNode.html(optionTextHtml);
    }
    var installmentPrice = _.isUndefined(skuData.formattedInstallmentPrice) ? '' : skuData.formattedInstallmentPrice;
    var $installmentNode = $(this).parent().siblings(".js-product-price-installment");
    if ($installmentNode.length) {
      $installmentNode.html(installmentPrice);
    }
    $(document).trigger('sku:select', skuData);
    updateUrlQueryParam('size', skuData.PRODUCT_SIZE);
    afterPayMessage();
    if (Drupal.settings.globals_variables.clearpay_uk) {
      Drupal.behaviors.clearPayMessageUKV1.initClearPay();
    }
  });
  function afterPayMessage() {
    if (!$('.js-afterpay').length) {
      return;
    }
    var amount = null;
    if ($('.js-price-for-installment').length) {
      amount = $('.js-price-for-installment').has('.on-sale').length
        ? $('.on-sale').html().split('$')[1].replace('.', '')
        : $('.js-price-for-installment').html().split('$')[1].replace('.', '');
    } else if ($('.js-selectBox-label').length) {
      amount = $('.js-selectBox-label option:selected').text().split('$')[1].replace('.', '');
    }
    if (amount !== null && typeof presentAfterpay.afterpayConfig === 'function') {
      presentAfterpay.afterpayConfig(amount);
    }
  }

  $skintypeSelect.one('change', function() {
    $(document).trigger('selectFirstShoppable');
  });

  $(document).on('sku:select', function(e, skuData) {
    //Down Price Updated for Shaded Products
    var price2 = (_.isUndefined(skuData.PRICE2)) ? '' : skuData.PRICE2;
    var isDiscountable = (_.isUndefined(skuData.DISCOUNTABLE) || skuData.DISCOUNTABLE == "0") ? false : true;
    var formattedPrice2 = (_.isUndefined(skuData.formattedPrice2)) ? '' : skuData.formattedPrice2;
    var formattedPrice = (_.isUndefined(skuData.formattedPrice)) ? '' : skuData.formattedPrice;
    var productSize = (_.isUndefined(skuData.PRODUCT_SIZE)) ? '' : skuData.PRODUCT_SIZE;
    var priceNode = $('.js-product-price-size').length ? $('.js-product-price-size') : $('.spp-product__price-size');
    var priceLoyaltyContainer = $('.js-price-loyalty-container', priceNode);
    var priceLoyaltyNode = $('.js-price-loyalty', priceNode).last().clone();
    if (price2) {
      productSize = (productSize!== null ? (productSize + '  ') : '');
      if (isDiscountable && priceLoyaltyNode && priceLoyaltyNode.length) {
        optionTextHtml = productSize + '<span class="product__price--non-sale">'+ formattedPrice2 +'</span> ';
        priceNode.html(optionTextHtml);
        optionTextHtml = '<span class="on-sale  product__price--sale js-price-loyalty">'+ formattedPrice +'</span>';
        priceLoyaltyContainer.html(optionTextHtml);
      } else {
        var optionTextHtml =  productSize +'<span class="product__price--non-sale">'+ formattedPrice2 +'</span><span class="on-sale  product__price--sale">'+ formattedPrice +'</span>';
        priceNode.html(optionTextHtml);
      }
    } else {
      if (productSize !== null && productSize !== '') {
        if (priceLoyaltyNode && priceLoyaltyNode.length) {
          var priceLoyaltyText = priceLoyaltyNode.find('.js-price-loyalty--text');
          var priceLoyaltyClass = isDiscountable ? "product-full__price" : "";
          var optionTextHtml = '<span class="product__price--bold ' + priceLoyaltyClass + '">' + formattedPrice + '</span>';
          if (!priceNode.hasClass('js-product-multiple-sizes')) {
            optionTextHtml = optionTextHtml + '<span class="product-full__price-size">' + productSize + '</span>';
          }
          priceLoyaltyText.html(optionTextHtml);
          priceLoyaltyContainer.html(priceLoyaltyNode);
        } else {
          priceNode.html(productSize + '&nbsp &nbsp<span class="product__price--bold">' + formattedPrice + '</span>');
        }
      } else {
        if (isDiscountable && priceLoyaltyNode && priceLoyaltyNode.length) {
          var priceLoyaltyText = priceLoyaltyNode.find('.js-price-loyalty--text');
          var optionTextHtml = '<span class="product-full__price product__price--bold">'+ formattedPrice +'</span>';
          priceLoyaltyText.html(optionTextHtml);
          priceLoyaltyContainer.html(priceLoyaltyNode);
        } else {
          priceNode.html(formattedPrice);
        }
      }
    }
    $(document).trigger('productQV:rendered:LoyaltyPrices', priceNode);
  });
  // initial state
  $(document).one('ajaxComplete', $sizeSelect, function(event) {
    var sizeUrlParamValue = getUrlQueryParam('size');
    if(!sizeUrlParamValue){
      $(this).trigger('selectFirstShoppable');
    }
  });
  // select first shoppable // inventory sorting
  $(document).on('selectFirstShoppable', $sizeSelect, function(event) {
    var selectInvSorted = $sizeSelect.find('option').data('inventory') || false;
    var $firstShoppableInvObj;
    var skuDataInv;
    if (!selectInvSorted || !$sizeSelect.length) {
      return;
    }
    var selectedSku = parseInt($sizeSelect.find('option:selected').not(':disabled').attr('data-sku-base-id'));
    var selectedSkuInventory = parseInt($sizeSelect.find('option:selected').not(':disabled').attr('data-inventory'));
    if (!selectedSku || (selectedSkuInventory === 1) || (selectedSkuInventory === 3)) {
      return;
    }
    if (selectedSkuInventory !== 1) {
      var firstShoppable = $sizeSelect.find('option[data-inventory]').not(':disabled');
      var $firstShoppableObj = $('option', $sizeSelect).filter('option[data-inventory]').not(':disabled');
      if (!firstShoppable) {
        var skuData = _.find(that.productData.skus, function(sku) {
          return sku.SKU_BASE_ID === selectedSku;
        });
        $($firstShoppableInvObj).prop('selected', true);
        $sizeSelect.selectBox('refresh');
        site.skuSelect(skuDataInv);
      } else {
        var firstShoppableInv = parseInt($(firstShoppable).attr('data-sku-base-id'));
        if (!firstShoppableInv) {
          return;
        }
        $firstShoppableInvObj = $('option', $sizeSelect).filter('[data-sku-base-id=' + firstShoppableInv + ']').not(':disabled');
        skuDataInv = _.find(that.productData.skus, function(sku) {
          return sku.SKU_BASE_ID === firstShoppableInv;
        });
        // skintype selects
        var $clonedSelect = $('.sizeSelectClone option:disabled');
        if ($clonedSelect.length) {
          $clonedSelect.remove();
        }
        $($firstShoppableInvObj).prop('selected', true);
        $sizeSelect.selectBox('refresh');
        site.skuSelect(skuDataInv);
      }
    } else {
      $sizeSelect.selectBox('refresh');
    }
  });
};
// reordering SKUS (shades/sizes) by inventory status L2
site.product.view.full.updateSwatchSizeInvStatus = function(products) {
  var allProducts = products;
  if (!allProducts.length) {
    return;
  }
  $.each(products, function(index, product) {
    var skus = product.skus;
    var $shadePicker = $('.js-shade-picker[data-product-id="' + product.PRODUCT_ID + '"]');
    var $sizeSelect = $('.spp-product__price-size-select[data-product-id="' + product.PRODUCT_ID + '"]');
    var $shadeSelect = $('.js-sku-menu[data-product-id="' + product.PRODUCT_ID + '"]');
    var sppSkus = [];
    var key;

    if ($shadePicker.length > 0) {
      var $swatches = $('[class*="swatches--"] .swatch', $shadePicker);
      $swatches.each(function(index, swatch) {
        var skuBaseID = $(this).data('sku-base-id');
        sppSkus.push('SKU' + skuBaseID);
      });
    }
    if ($sizeSelect.length > 0) {
      var $sizes = $('option', $sizeSelect);
      $sizes.each(function(index, swatch) {
        var skuBaseID = $(this).data('sku-base-id');
        sppSkus.push('SKU' + skuBaseID);
      });
    }

    var inventoryOrder = {};
    if ($shadePicker.length > 0) {
      $.each(skus, function(index, sku) {
        // update shade picker
        var skuBaseID = sku.SKU_ID;
        skuBaseID = skuBaseID.replace('SKU', '');
        var $swatch = $(".swatch[data-sku-base-id='" + skuBaseID + "']", $shadePicker).parent('[class*="swatches--"]');
        $swatch.attr('data-inventory', sku.INVENTORY_STATUS);
        if (inventoryOrder.hasOwnProperty(sku.INVENTORY_STATUS)) {
          inventoryOrder[sku.INVENTORY_STATUS].push($swatch);
        } else {
          inventoryOrder[sku.INVENTORY_STATUS] = [$swatch];
        }
      });
      // push inventory status 2,4,5,6 and 7 to bottom of the list
      for (key in inventoryOrder) {
        if ((parseInt(key) !== 1) && (parseInt(key) !== 3)) {
          $.each(inventoryOrder[key], function(index, product) {
            $shadePicker.append(product);
          });
        }
      }
    }
    // shade select
    if ($shadeSelect.length > 0) {
      $.each($shadeSelect, function(index) {
        var $thisShadeSelect = $(this);
        var inventoryOrderShades = {};
        $.each(skus, function(index, sku) {
          var skuBaseID = sku.SKU_ID;
          skuBaseID = skuBaseID.replace('SKU', '');
          var $shadeOpt = $("option[data-sku-base-id='" + skuBaseID + "']", $thisShadeSelect);
          $shadeOpt.attr('data-inventory', sku.INVENTORY_STATUS);
          if (inventoryOrderShades.hasOwnProperty(sku.INVENTORY_STATUS)) {
            inventoryOrderShades[sku.INVENTORY_STATUS].push($shadeOpt);
          } else {
            inventoryOrderShades[sku.INVENTORY_STATUS] = [$shadeOpt];
          }
        });
        for (key in inventoryOrderShades) {
          if ((parseInt(key) !== 1) && (parseInt(key) !== 3)) {
            $.each(inventoryOrderShades[key], function(index, product) {
              $thisShadeSelect.append(product);
            });
          }
        }
      });
    }
    //size select
    var inventoryOrderSizes = {};
    if ($sizeSelect.length > 0) {
      $.each(skus, function(index, sku) {
        // update size dropdown
        var skuBaseID = sku.SKU_ID;
        skuBaseID = skuBaseID.replace('SKU', '');
        var $size = $("option[data-sku-base-id='" + skuBaseID + "']", $sizeSelect);
        $size.attr('data-inventory', sku.INVENTORY_STATUS);
        if (inventoryOrderSizes.hasOwnProperty(sku.INVENTORY_STATUS)) {
          inventoryOrderSizes[sku.INVENTORY_STATUS].push($size);
        } else {
          inventoryOrderSizes[sku.INVENTORY_STATUS] = [$size];
        }
      });
      // reorder size dropdown
      // push inventory greater than one to the bottom of the list
      for (key in inventoryOrderSizes) {
        if ((parseInt(key) !== 1) && (parseInt(key) !== 3)) {
          $.each(inventoryOrderSizes[key], function(index, product) {
            $sizeSelect.append(product[0]);
          });
        }
      }
      var sizeUrlParamValue = getUrlQueryParam('size');
      if(!sizeUrlParamValue){
        $sizeSelect.selectBox('refresh');
        $(document).trigger('selectFirstShoppable');
      }
    }
  });
};

$(document).on('spp_inventory_status_sort', function(e, data) {
  var $sppGrid = $('.spp-product', this);
  var sppSortSku = $($sppGrid).data('inventory-sort') || false;
  if (!sppSortSku) {
    return;
  }
  site.product.view.full.updateSwatchSizeInvStatus(data.products);
});

$(function() {
  if (!page_data || !page_data['catalog-spp'] || !Array.isArray(page_data['catalog-spp']['products'])) {
    return null;
  }
  if (page_data['catalog-spp']['products'].length < 1) {
    return null;
  }
  var productData = page_data['catalog-spp']['products'][0];
  site.product.view.full({productData: productData});
  $(document).trigger('sku:select', productData.defaultSku || productData.skus[0]);
});
